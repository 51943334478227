import { useEffect, useRef } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}






export default function Schedule({activities}) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const getEarliestAndLatestTimes = (activities) => {
    let earliest = Infinity;
    let latest = -Infinity;
  
    activities.forEach(activity => {
      const [start, end] = activity.time.split(' - ');
      const [startHour, startMinute] = start.split('h').map(Number);
      const [endHour, endMinute] = end.split('h').map(Number);
      const startTime = startHour * 60 + startMinute;
      const endTime = endHour * 60 + endMinute;
  
      if (startTime < earliest) earliest = startTime;
      if (endTime > latest) latest = endTime;
    });
  
    return [earliest, latest];
  };
  
  const [earliest, latest] = getEarliestAndLatestTimes(activities);
  const earliestHour = Math.floor(earliest / 60);
  const latestHour = Math.ceil(latest / 60);

  useEffect(() => {
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) * currentMinute) / 1440;
  }, []);
  const timeToGridRow = (time) => {
    const [start, end] = time.split(' - ');
    const [startHour, startMinute] = start.split('h').map(Number);
    const [endHour, endMinute] = end.split('h').map(Number);
  
    const startRow = (startHour - earliestHour) * 2 + (startMinute > 0 ? 2 : 1);
    const endRow = (endHour - earliestHour) * 2 + (endMinute > 0 ? 2 : 1);
  
    return `${startRow} / ${endRow}`;
  };

  return (
    <div className={`flex h-full flex-col ${activities ? '' : 'hidden'}`}>
      <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div style={{ width: '150%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div ref={containerNav} className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
            <div className="grid grid-cols-5 text-sm leading-6 text-gray-500 sm:hidden"
            >
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                M
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                T
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                W
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                T
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                F
              </button>
            </div>

            <div className="-mr-px hidden grid-cols-5 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
              <div className="col-start-1 col-span-1 flex items-center justify-center py-3">
                <span>Mon</span>
              </div>
              <div className="col-start-2 col-span-1 flex items-center justify-center py-3">
                <span>Tue</span>
              </div>
              <div className="col-start-3 col-span-1 flex items-center justify-center py-3">
                <span>Wed</span>
              </div>
              <div className="col-start-4 col-span-1 flex items-center justify-center py-3">
                <span>Thu</span>
              </div>
              <div className="col-start-5 col-span-1 flex items-center justify-center py-3">
                <span>Fri</span>
              </div>
            </div>
          </div>
          <div className="flex flex-auto overflow-x-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-5 grid-rows-1" style={{ minWidth: '5rem' }}>
              <div
                className="col-start-1 col-end-6 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: `repeat(${(latestHour - earliestHour) * 2}, minmax(3.5rem, 1fr))` }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                {Array.from({ length: (latestHour - earliestHour) * 2 }, (_, index) => (
                  <div key={index} className="row-span-1">
                    {index % 2 === 0 ? (
                      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {earliestHour + Math.floor(index / 2)}:00
                      </div>
                    ) : (
                      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {earliestHour + Math.floor(index / 2)}:30
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <ol
                className="col-start-1 col-end-6 row-start-1 grid"
                style={{ gridTemplateColumns: 'repeat(5, minmax(7rem, 1fr))', gridTemplateRows: `repeat(${(latestHour - earliestHour) * 2}, minmax(1.75rem, 1fr)) auto` }}
              >
                {activities.map((activity, index) =>
                  Object.entries(activity).map(
                    ([day, event]) =>
                      day !== 'time' &&
                      event && (
                        <li
                          key={`${day}-${index}`}
                          className={`relative mt-px flex ${
                            day === 'Monday' ? 'col-start-1' : ''
                          } ${day === 'Tuesday' ? 'col-start-2' : ''} ${
                            day === 'Wednesday' ? 'col-start-3' : ''
                          } ${day === 'Thursday' ? 'col-start-4' : ''} ${
                            day === 'Friday' ? 'col-start-5' : ''
                          }`}
                          style={{ gridRow: timeToGridRow(activity.time) }}
                        >
                          <a
                            href="#"
                            className="group absolute inset-1 flex flex-col  rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                          >
                            <p className="order-1 font-semibold text-blue-700">{event}</p>
                            <p className="text-blue-500 group-hover:text-blue-700">
                              <time>{activity.time}</time>
                            </p>
                          </a>
                        </li>
                      )
                  )
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
