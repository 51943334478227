// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBL4x14OodukhGFeLIBSQPbRgNKAlT5-So",
    authDomain: "ihubss.firebaseapp.com",
    projectId: "ihubss",
    storageBucket: "ihubss.appspot.com",
    messagingSenderId: "20531912185",
    appId: "1:20531912185:web:c0bef893e174b308b3f128"
  };
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
