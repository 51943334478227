import React from 'react';
import { Link } from 'react-router-dom';

function Card({ title, subtitle, img, facility }) {
  return (
    <Link to={`/facilities/${facility}`}>
      <div 
        className={`relative w-72 h-64 cursor-pointer backdrop-brightness-50 rounded-2xl overflow-hidden bg-center`}
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className='w-full h-full backdrop-brightness-75 text-center p-2 text-white card flex justify-center flex-col hover:backdrop-brightness-50 transition-all duration-100'>
          <h3 className='text-3xl text-white'>{title}</h3>
          <p className='translate-y-10'>{subtitle}</p>
        </div>
      </div>
    </Link>
  );
}

export default Card;
