import Button from "../../../components/Button";

export default function Exams() {
    return (
        <section>
            <div className="flex flex-col-reverse md:flex-row gap-10 justify-center items-center mx-20">
                <div className="md:basis-1/2">
                    <img className="rounded-3xl" alt="" src="https://source.unsplash.com/random/800x500"></img>
                </div>
                <div className="md:basis-1/2">
                    <h2>Exams</h2>
                    <div className="prose">
                        <p>When it comes to examinations, we offer comprehensive support across all our exam centres. iHubs facilitates IGCSE and A-level examinations from both Pearson and Cambridge. Additionally, iHubs supports online exams through our portal, giving students the option to take their exams online rather than in traditional settings.</p>
                        <p>Students enrolled with Inventum International Online School and iHubs also have the opportunity to participate in internal assessments. These assessments are designed to produce official school reports, ensuring academic integrity and quality assurance.</p>
                        <Button text="Apply" />
                    </div>
                </div>
            </div>
        </section>
    )
}