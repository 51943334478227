import About from "./components/about";
import Exams from "./components/exams";
import Fascilities from "./components/fascilities";
import Hero from "./components/hero";
import Social from "./components/social";

export default function Home() {
    return (
        <div className="">
            <Hero />
            <About />
            <Fascilities />
            <Exams />
        </div>
    )
} 