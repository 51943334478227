import logo from './logo.svg';
import './App.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './output.css'
import './input.css'
import Home from './pages/home/page';
import FacilityDetail from './pages/facilities/FacilityDetail';
import About from './pages/about/page';
import Service from './pages/service/page';
import FAQ from './components/FAQ';
import Location from './pages/location/page';
import Facility from './pages/facilities/page';
import Contact from './pages/contact/page';
import useScrollToTop from './components/useScrollToTop';

function ScrollToTop() {
  useScrollToTop();

  return null; // This component doesn't render anything
}

function App() {
  return (
    <div>
      <Router>
      <ScrollToTop />
        <Suspense fallback={ <div className='h-screen w-screen bg-dd pt-40 text-7xl text-center mx-auto justify-center text-white flex items-center'>loading...</div>} >
          <div className='flex flex-col justify-between min-h-screen overflow-x-clip w-screen'>
              <Navbar />
              <Routes>
                <Route path='/' element={ <Home />}/>
                <Route path="/about" element={<About />} />
                <Route path="/whatwedo" element={<Service />} />
                <Route path="/apply" element={<Contact />} />
                <Route path="/facilities" element={<Facility />} />
                <Route path="/facilities/:facility" element={<FacilityDetail />} />
                <Route path="/locations/:location" element={<Location />} />
              </Routes>
              <FAQ />
              <Footer />
          </div>
        </Suspense>
      </Router>
     
    </div>
  );
}

export default App;
