import Button from "../../../components/Button";

export default function Stats() {
    return (
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              We approach schooling as something that adds to our lives and adds value to world.
            </h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
            At iHubs, we believe education should not only impart knowledge but also enrich lives and contribute positively to society. Our approach focuses on holistic development, combining rigorous academics with opportunities for personal growth and social interaction. By preparing our students for the challenges of the future, we aim to create confident and capable individuals who can make meaningful contributions to the world.
            </p>
            <Button />
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">20</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-gray-900">Global reach</p>
                <p className="mt-2 text-base leading-7 text-gray-600">
                iHubs has centers in over 20 countries, providing students with opportunities for social development worldwide.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">+15</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">Innovative Facilities</p>
                <p className="mt-2 text-base leading-7 text-gray-400">
                  Our hubs are equipped with state-of-the-art VR/AR rooms, digital recording studios, and art studios to foster creativity and engagement.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">{">"}90%</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-white">Life Skills Programs</p>
                <p className="mt-2 text-base leading-7 text-indigo-200">
                Over 90% of our students participate in "Adulting 101" classes, ensuring they are well-prepared for independent living and future challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
    )
}