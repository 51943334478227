import { Link } from "react-router-dom"
import Button from "./Button"
import Newsletter from "../pages/location/components/Newsletter"

export default function Footer() {
    const today = new Date()
    return (
        <footer >
            <div>
                <Newsletter general />
            </div>
            <div className="border-x-[1px] text-lightgray mt-20  bottom-0">
                <div className="flex justify-between p-5 md:flex-row flex-col">
                    <p className="px-4">Copyright iHubs {today.getFullYear()}</p>
                    <div className="flex gap-5 px-4">
                        <Link>Privacy Policy</Link>
                        <Link>Terms of use</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}