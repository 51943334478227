import Card from "../home/components/card";

export default function Facility() {
    const cardsData = [
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "VR Room", subtitle: "Experience the future", facility: "vr-room" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Creative Arts", subtitle: "Express yourself", facility: "creative-arts" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Digital Creativity", subtitle: "Create media", facility: "digital-creativity" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Adulting 101", subtitle: "Life skills", facility: "adulting-101" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Socialisation", subtitle: "Connect positively", facility: "socialisation" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Activities", subtitle: "Engage & compete", facility: "activities" },
        { img: "https://media.licdn.com/dms/image/D4D12AQGa6d5pgbo3Og/article-cover_image-shrink_720_1280/0/1691221334094?e=2147483647&v=beta&t=pm0jzIObdjwgqr7vdOFl6nPNPfSOaW9LJn0svCXqrLM", title: "Examination Centre", subtitle: "Cambridge & Pearson", facility: "examination-centre" },
      ];
    return (
        <section className="mt-20">
             <h2 className='text-center'>Facilities</h2>
            <div className="flex flex-wrap gap-2 justify-center max-w-4xl mx-auto p-2">
                {cardsData.map((card, index) => (
                    <Card key={index} img={card.img} title={card.title} subtitle={card.subtitle} facility={card.facility ?? 'not-found'} />
                ))}
            </div>
        </section>
    )
}